import { createApi } from '@reduxjs/toolkit/query/react'

import { LIMIT_EXPORT, PartnerType } from '@/config/constants'
import {
  ADMIN_DASHBOARD_ACTIVE_STORE,
  ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT,
  ADMIN_DASHBOARD_GRADE_RESULT,
  ADMIN_DASHBOARD_GRADE_RESULT_EXPORT,
  ADMIN_DASHBOARD_NUMBER_OF_DEVICE,
  ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_ACTIVITY,
  ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT,
  ADMIN_DASHBOARD_PROMOTER_TRANSACTION,
  ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT,
  ADMIN_DASHBOARD_TRADE_IN,
  ADMIN_DASHBOARD_TRANSACTION_OVERVIEW,
  ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT,
  ADMIN_DASHBOARD_VENDOR_CONTRIBUTION,
  ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT,
} from '@/config/endpoints'
import { BaseRequest } from '@/types/common'
import {
  DashboardActiveStoreExportRequest,
  DashboardActiveStoreExportResponse,
  DashboardActiveStoreRequest,
  DashboardActiveStoreResponse,
  DashboardChart,
  DashboardExportResponse,
  DashboardGradeResultExportRequest,
  DashboardGradeResultExportResponse,
  DashboardGradeResultRequest,
  DashboardGradeResultResponse,
  DashboardNumberOfDeviceExportRequest,
  DashboardNumberOfDeviceExportResponse,
  DashboardNumberOfDeviceRequest,
  DashboardNumberOfDeviceResponse,
  DashboardPromoterExportRequest,
  DashboardPromoterExportResponse,
  DashboardPromoterInactiveExportResponse,
  DashboardPromoterRequest,
  DashboardPromoterResponse,
  DashboardQueryFiltersRequest,
  DashboardTradeInRequest,
  DashboardTradeInResponse,
  DashboardTransactionOverviewExportRequest,
  DashboardTransactionOverviewExportResponse,
  DashboardTransactionOverviewRequest,
  DashboardTransactionOverviewResponse,
  DashboardVendorContributionExportRequest,
  DashboardVendorContributionExportResponse,
  DashboardVendorContributionRequest,
  DashboardVendorContributionResponse,
} from '@/types/dashboard'
import { baseQuery, composeRequest } from '@/utils/api'

const api = createApi({
  baseQuery,
  reducerPath: 'dashboard',
  tagTypes: ['Dashboard'],
  refetchOnMountOrArgChange: true,
  keepUnusedDataFor: 259200, // 3 days
  endpoints: (builder) => ({
    exportAll: builder.query<DashboardExportResponse, BaseRequest<unknown, DashboardQueryFiltersRequest>>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: DashboardExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: {
            transactionOverview: [],
            numberOfDevice: [],
            gradeResult: [],
            vendorContribution: [],
            promoterTransaction: [],
            activeStore: [],
            promoterActivity: [],
          },
        }

        const newRequest = { ...request }

        // transaction overview
        let skipTransactionOverview = 0
        let hasMoreTransactionOverview = true

        while (hasMoreTransactionOverview) {
          try {
            const { data: transactionOverviewData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipTransactionOverview } },
                ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT
              ),
              method: 'GET',
            })

            const transactionOverviewResponse = transactionOverviewData as DashboardTransactionOverviewExportResponse

            if (transactionOverviewResponse && transactionOverviewResponse.data) {
              transactionOverviewResponse.data.map((item) => response.data.transactionOverview.push(item))
            }

            skipTransactionOverview += LIMIT_EXPORT
            hasMoreTransactionOverview = transactionOverviewResponse.data.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        // number of device
        let skipNumberOfDevice = 0
        let hasMoreNumberOfDevice = true

        while (hasMoreNumberOfDevice) {
          try {
            const { data: numberOfDeviceData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipNumberOfDevice } },
                ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT
              ),
              method: 'GET',
            })

            const numberOfDeviceResponse = numberOfDeviceData as DashboardTransactionOverviewExportResponse

            if (numberOfDeviceResponse && numberOfDeviceResponse.data) {
              numberOfDeviceResponse.data.map((item) => response.data.numberOfDevice.push(item))
            }

            skipNumberOfDevice += LIMIT_EXPORT
            hasMoreNumberOfDevice = numberOfDeviceResponse.data.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        // grade result
        let skipGradeResult = 0
        let hasMoreGradeResult = true

        while (hasMoreGradeResult) {
          try {
            const { data: gradeResultData } = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip: skipGradeResult } },
                ADMIN_DASHBOARD_GRADE_RESULT_EXPORT
              ),
              method: 'GET',
            })

            const gradeResultResponse = gradeResultData as DashboardTransactionOverviewExportResponse

            if (gradeResultResponse && gradeResultResponse.data) {
              gradeResultResponse.data.map((item) => response.data.gradeResult.push(item))
            }

            skipGradeResult += LIMIT_EXPORT
            hasMoreGradeResult = gradeResultResponse.data.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        // vendor contribution
        try {
          const { data: vendorContributionData } = await baseQuery({
            ...composeRequest(request, ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT),
            method: 'GET',
          })

          const vendorContributionResponse = vendorContributionData as DashboardVendorContributionExportResponse

          if (vendorContributionResponse && vendorContributionResponse.data) {
            response.data.vendorContribution = vendorContributionResponse.data
          }
        } catch (e) {
          console.error(e)
        }

        // promoter transaction
        try {
          const { data: promoterTransactionData } = await baseQuery({
            ...composeRequest(request, ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT),
            method: 'GET',
          })

          const promoterTransactionResponse = promoterTransactionData as DashboardPromoterExportResponse

          if (promoterTransactionResponse && promoterTransactionResponse.data) {
            response.data.promoterTransaction = promoterTransactionResponse.data
          }

          const { data: promoterInactiveData } = await baseQuery({
            ...composeRequest(request, ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT),
            method: 'GET',
          })

          const promoterInactiveResponse = promoterInactiveData as DashboardPromoterInactiveExportResponse

          if (promoterInactiveResponse && promoterInactiveResponse.data) {
            response.data.promoterTransaction = [
              ...response.data.promoterTransaction,
              ...promoterInactiveResponse.data.map((d) => ({
                ...d,
                status: d.promoterStatus,
                promoterStatus: undefined,
              })),
            ]
          }
        } catch (e) {
          console.error(e)
        }

        // active store
        let skipActiveStore = 0
        let hasMoreActiveStore = true

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          hasMoreActiveStore = false
        }

        while (hasMoreActiveStore) {
          try {
            const { data: activeStoreData } = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...request.query,
                    filters: {
                      ...request.query?.filters,
                      partnerTypeId: undefined,
                    },
                    limit: LIMIT_EXPORT,
                    skip: skipActiveStore,
                  },
                },
                ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT
              ),
              method: 'GET',
            })

            const activeStoreResponse = activeStoreData as DashboardActiveStoreExportResponse

            if (activeStoreResponse && activeStoreResponse.data) {
              activeStoreResponse.data.map((item) => response.data.activeStore.push(item))
            }

            skipActiveStore += LIMIT_EXPORT
            hasMoreActiveStore = activeStoreResponse.data.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        // promoter activity
        try {
          if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
            response.data.promoterActivity = []
          } else {
            const promoterActivityRequest = { ...request }

            if (request.query && request.query.filters && request.query.filters.partnerTypeId) {
              promoterActivityRequest.query = {
                ...request.query,
                filters: {
                  ...request.query.filters,
                  partnerTypeId: undefined,
                },
              }
            }

            const { data: promoterActivityData } = await baseQuery({
              ...composeRequest(promoterActivityRequest, ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT),
              method: 'GET',
            })

            const promoterActivityResponse = promoterActivityData as DashboardPromoterExportResponse

            if (promoterActivityResponse && promoterActivityResponse.data) {
              response.data.promoterActivity = promoterActivityResponse.data
            }

            const { data: promoterInactiveData } = await baseQuery({
              ...composeRequest(promoterActivityRequest, ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT),
              method: 'GET',
            })

            const promoterInactiveResponse = promoterInactiveData as DashboardPromoterInactiveExportResponse

            if (promoterInactiveResponse && promoterInactiveResponse.data) {
              response.data.promoterActivity = [
                ...response.data.promoterActivity,
                ...promoterInactiveResponse.data.map((d) => ({
                  ...d,
                  status: d.promoterStatus,
                  promoterStatus: undefined,
                })),
              ]
            }
          }
        } catch (e) {
          console.error(e)
        }

        return { data: response }
      },
    }),
    tradeIn: builder.query<DashboardTradeInResponse, DashboardTradeInRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_TRADE_IN),
        method: 'GET',
      }),
    }),
    transactionOverview: builder.query<DashboardTransactionOverviewResponse, DashboardTransactionOverviewRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_TRANSACTION_OVERVIEW),
        method: 'GET',
      }),
    }),
    transactionOverviewExport: builder.query<
      DashboardTransactionOverviewExportResponse,
      DashboardTransactionOverviewExportRequest
    >({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardTransactionOverviewExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_TRANSACTION_OVERVIEW_EXPORT
              ),
              method: 'GET',
            })
            const { data: transactionOverview } = response.data as DashboardTransactionOverviewExportResponse

            if (data) {
              transactionOverview.map((item) => data.data.push(item))
            }

            skip += LIMIT_EXPORT
            hasMore = transactionOverview.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    numberOfDevice: builder.query<DashboardNumberOfDeviceResponse, DashboardNumberOfDeviceRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_NUMBER_OF_DEVICE),
        method: 'GET',
      }),
    }),
    numberOfDeviceExport: builder.query<DashboardNumberOfDeviceExportResponse, DashboardNumberOfDeviceExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardNumberOfDeviceExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_NUMBER_OF_DEVICE_EXPORT
              ),
              method: 'GET',
            })
            const { data: numberOfDevice } = response.data as DashboardNumberOfDeviceExportResponse

            if (data) {
              numberOfDevice.map((item) => data.data.push(item))
            }

            skip += LIMIT_EXPORT
            hasMore = numberOfDevice.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    gradeResult: builder.query<DashboardGradeResultResponse, DashboardGradeResultRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_GRADE_RESULT),
        method: 'GET',
      }),
    }),
    gradeResultExport: builder.query<DashboardGradeResultExportResponse, DashboardGradeResultExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardGradeResultExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        const newRequest = { ...request }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                { query: { ...newRequest.query, limit: LIMIT_EXPORT, skip } },
                ADMIN_DASHBOARD_GRADE_RESULT_EXPORT
              ),
              method: 'GET',
            })
            const { data: gradeResult } = response.data as DashboardGradeResultExportResponse

            if (data) {
              gradeResult.map((item) => data.data.push(item))
            }

            skip += LIMIT_EXPORT
            hasMore = gradeResult.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    vendorContribution: builder.query<DashboardVendorContributionResponse, DashboardVendorContributionRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_VENDOR_CONTRIBUTION),
        method: 'GET',
      }),
    }),
    vendorContributionExport: builder.query<
      DashboardVendorContributionExportResponse,
      DashboardVendorContributionExportRequest
    >({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_VENDOR_CONTRIBUTION_EXPORT),
        method: 'GET',
      }),
    }),
    promoterTransaction: builder.query<DashboardPromoterResponse, DashboardPromoterRequest>({
      query: (request) => ({
        ...composeRequest(request, ADMIN_DASHBOARD_PROMOTER_TRANSACTION),
        method: 'GET',
      }),
    }),
    promoterTransactionExport: builder.query<DashboardPromoterExportResponse, DashboardPromoterExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: DashboardPromoterExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        try {
          const { data: promoterTransactionData } = await baseQuery({
            ...composeRequest(request, ADMIN_DASHBOARD_PROMOTER_TRANSACTION_EXPORT),
            method: 'GET',
          })

          const promoterTransactionResponse = promoterTransactionData as DashboardPromoterExportResponse

          if (promoterTransactionResponse && promoterTransactionResponse.data) {
            response.data = promoterTransactionResponse.data
          }

          const { data: promoterInactiveData } = await baseQuery({
            ...composeRequest(request, ADMIN_DASHBOARD_PROMOTER_INACTIVE_EXPORT),
            method: 'GET',
          })

          const promoterInactiveResponse = promoterInactiveData as DashboardPromoterInactiveExportResponse

          if (promoterInactiveResponse && promoterInactiveResponse.data) {
            response.data = [
              ...response.data,
              ...promoterInactiveResponse.data.map((d) => ({
                ...d,
                status: d.promoterStatus,
                promoterStatus: undefined,
              })),
            ]
          }
        } catch (e) {
          console.error(e)
        }

        return { data: response }
      },
    }),
    activeStore: builder.query<DashboardActiveStoreResponse, DashboardActiveStoreRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardActiveStoreResponse = {
          success: true,
          code: 'INTERNAL',
          message: '',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        const response = await baseQuery({
          ...composeRequest(
            {
              query: {
                ...request.query,
                filters: {
                  ...request.query?.filters,
                  partnerTypeId: undefined,
                },
              },
            },
            ADMIN_DASHBOARD_ACTIVE_STORE
          ),
          method: 'GET',
        })

        const { data: dataActiveStore } = response.data as DashboardActiveStoreResponse

        dataActiveStore.forEach((item: DashboardChart) => data.data.push(item))

        return { data }
      },
    }),
    activeStoreExport: builder.query<DashboardActiveStoreExportResponse, DashboardActiveStoreExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const data: DashboardActiveStoreExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        let skip = 0
        let hasMore = true

        while (hasMore) {
          try {
            const response = await baseQuery({
              ...composeRequest(
                {
                  query: {
                    ...request.query,
                    filters: {
                      ...request.query?.filters,
                      partnerTypeId: undefined,
                    },
                    limit: LIMIT_EXPORT,
                    skip,
                  },
                },
                ADMIN_DASHBOARD_ACTIVE_STORE_EXPORT
              ),
              method: 'GET',
            })
            const { data: activeStore } = response.data as DashboardActiveStoreExportResponse

            if (data) {
              activeStore.map((item) => data.data.push(item))
            }

            skip += LIMIT_EXPORT
            hasMore = activeStore.length > 0
          } catch (e) {
            console.error(e)
          }
        }

        return { data }
      },
    }),
    promoterActivity: builder.query<DashboardPromoterResponse, DashboardPromoterRequest>({
      queryFn: async (request, _api, _extraOptions, baseQuery) => {
        const data: DashboardPromoterResponse = {
          success: true,
          code: 'INTERNAL',
          message: '',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data }
        }

        const response = await baseQuery({
          ...composeRequest(
            {
              query: {
                ...request.query,
                filters: {
                  ...request.query?.filters,
                  partnerTypeId: undefined,
                },
              },
            },
            ADMIN_DASHBOARD_PROMOTER_ACTIVITY
          ),
          method: 'GET',
        })

        const { data: dataPromoterActivity } = response.data as DashboardPromoterResponse

        dataPromoterActivity.forEach((item: DashboardChart) => data.data.push(item))

        return { data }
      },
    }),
    promoterActivityExport: builder.query<DashboardPromoterExportResponse, DashboardPromoterExportRequest>({
      queryFn: async (request, api, extraOptions, baseQuery) => {
        const response: DashboardPromoterExportResponse = {
          success: true,
          code: 'INTERNAL',
          message: 'OK',
          data: [],
        }

        if (request.query?.filters?.partnerTypeId === String(PartnerType.LOCAL)) {
          return { data: response }
        }

        const newRequest = { ...request }

        if (request.query && request.query.filters && request.query.filters.partnerTypeId) {
          newRequest.query = {
            ...request.query,
            filters: {
              ...request.query.filters,
              partnerTypeId: undefined,
            },
          }
        }

        try {
          const { data: promoterTransactionData } = await baseQuery({
            ...composeRequest(newRequest, ADMIN_DASHBOARD_PROMOTER_ACTIVITY_EXPORT),
            method: 'GET',
          })

          const promoterTransactionResponse = promoterTransactionData as DashboardPromoterExportResponse

          if (promoterTransactionResponse && promoterTransactionResponse.data) {
            response.data = promoterTransactionResponse.data
          }

          const { data: promoterInactiveData } = await baseQuery({
            ...composeRequest(newRequest, ADMIN_DASHBOARD_PROMOTER_NOT_LOGIN_EXPORT),
            method: 'GET',
          })

          const promoterInactiveResponse = promoterInactiveData as DashboardPromoterInactiveExportResponse

          if (promoterInactiveResponse && promoterInactiveResponse.data) {
            response.data = [
              ...response.data,
              ...promoterInactiveResponse.data.map((d) => ({
                ...d,
                status: d.promoterStatus,
                promoterStatus: undefined,
              })),
            ]
          }
        } catch (e) {
          console.error(e)
        }

        return { data: response }
      },
    }),
  }),
})

// Export hooks for usage in functional components
export const { util: dashboardUtil } = api

// Export endpoints for use in SSR
// export const {  } = api.endpoints

export default api
